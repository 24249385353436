
<script setup>
    import { ref } from "vue";
    // import someWayToGetObjectID from "someplace";
    import { objectID, objectName } from "temp.walldevice.utils.ts";
    import imgHelseMidt from "temp.walldevice.imgHelseMidt.ts";
    import imgO365 from "temp.walldevice.imgO365.ts";
    import svgO365 from "temp.walldevice.svgO365.vue";

    const logo = ref("imgO365");

    // setInterval(() => {
        // logo.value = logo.value === "imgHelseMidt" ? "imgO365" : "imgHelseMidt";
    // }, 1000);

    const props = defineProps({

    });
</script>

<template>
    <div class="d-flex flex-column align-items-center justify-content-between" style="position: absolute; inset: 0;">
        <div class="flex-shrink-0 d-flex flex-column align-items-center" style="margin-bottom: -30vw;">
            <!-- logo -->
            <template v-if="logo === 'imgHelseMidt'">
                <div>
                    <div>
                        <img :src="imgHelseMidt" style="width: 100vw;" />
                    </div>
                    <div class="d-flex justify-content-end align-items-center" style="position: relative; bottom: 20vw; right: 10vw;">
                        <div class="fw-bold uppercase tracking-4 text-muted" style="font-size: 3vw;">{{ $t("Powered by Omega 365") }}</div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div style="margin-bottom: 4rem;">
                    <!-- <img :src="imgO365" style="width: 60vw;" /> -->
                    <svgO365 style="width: 50vw;" />
                </div>
            </template>

            <!-- object -->
            <div class="fw-medium font-8" style="position: relative; bottom: 10vw;">
                {{ objectName }}
            </div>
        </div>

        <div class="flex-grow-1 d-flex flex-column" style="position: relative;">
            <slot />
        </div>

        <template v-if="$slots.viewport">
            <div style="position: absolute; inset: 0;">
                <slot name="viewport" />
            </div>
        </template>

        <template v-if="$slots.center">
            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <slot name="center" />
            </div>
        </template>

        <template v-if="$slots.bottom">
            <div style="position: absolute; bottom: 1rem;">
                <slot name="bottom" />
            </div>
        </template>
    </div>
</template>
